<template>
  <div>
    <a-modal :visible="visible" title="更换司机" :bodyStyle="{padding:'10px'}" width="40%" @ok="submit" @cancel="cancel">
      <div class="m-b2">
        <div class="title"><span>*</span>选择司机</div>
        <div class="tabBox">
          <div>
            <a-input style="width:23%" v-model:value="name" placeholder="请输入" />
            <a-button class="m-l1" @click="search" type="primary">搜索</a-button>
          </div>
          <div class="m-b1"></div>
          <ly-table :columns="columns" size="small" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
            <template #add="{ record }">
              <a v-if="record.id === celID">已选择</a>
              <div v-else class="flex ju-center">
                <div class="nameBox" @click="celDriver(record)"><a>选择该司机</a></div>
              </div>
            </template>
          </ly-table>
        </div>
      </div>
      <div>
        <div class="title"><span>*</span>原因</div>
        <a-textarea v-model:value="reason" placeholder="原因" :rows="4" />
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { driverPage } from '@/api/crmManagement/crm'
import { changeDriver } from '@/api/transport/vehicle'
import { message } from 'ant-design-vue'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  carrierId: {
    type: String,
    default: ''
  },
  transportId: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:visible','success'])
const reason = ref('')
const celID = ref('')
const loading = ref(false)
const data = ref([])
const name = ref('')
const pagination = ref({
  current: 1,
  pageSize: 5,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
//搜索
const search = () => {
  pagination.value.current = 1
  reqDriverPage()
}
const submit = () => {
  if (!celID.value) {
    message.error('请选择司机')
    return
  }
  if (!reason.value) {
    message.error('请输入原因')
    return
  }
  changeDriver({
    transportId: props.transportId,
    reason: reason.value,
    driverId: celID.value
  }).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    emit('success')
    cancel()
  })
}
const cancel = () => {
  emit('update:visible', false)
}
//选择司机
const celDriver = (record) => {
  celID.value = record.id
}
const pageChange = (e) => {
  pagination.value = e
  reqDriverPage()
}
const reqDriverPage = () => {
  loading.value = true
  driverPage({
    customerId: props.carrierId,
    name: name.value,
    current: pagination.value.current,
    size: pagination.value.pageSize
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  reqDriverPage()
})
const columns = ref([
  {
    title: '司机姓名',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '司机电话',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: '20%',
    slots: {
      customRender: 'add'
    }
  },
])
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 5px;
  span {
    color: red;
  }
}
.tabBox {
  border: 1px solid rgba(238, 238, 238, 1);
  width: 100%;
  padding: 8px;
}

.nameBox {
  padding: 4px;
  width: 80px;
  font-size: 12px;
  background: rgba(242, 242, 242, 1);
}
</style>