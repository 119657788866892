<template>
  <div>
    <a-modal :visible="visible" footer="" :centered="true" :title="title" width="47%" @cancel="cancel">
      <div>
        <div class="titel">运输车信息</div>
        <div class="infoBox flex wrap">
          <div v-for="(item,index) in info" :key="item.id" class="flex  m-b1">
            <div style="width:65px;color:#777777">{{ item.label }}</div>
            <div style="width:65px;color:#191919" :style="{width:index%3===0?'220px':(index+1)%3===0?'auto':'190px'}">{{ item.value }}  <a @click="changeDriver" v-if="item.add">更换</a></div>
        
          </div>
        </div>
      </div>
      <div>
        <div class="titel m-t1 m-b1 flex ju-between al-center">已安排车辆<div v-if="msgInfo.truckStatus?.value === 3"><a-button @click="allArrive" type="primary">批量到达</a-button></div>
        </div>
        <div class="orderVehicle">
          <ly-table :columns="columns" size="small" :data="data" rowKey="orderVehicleId">
            <template #vinNo="{ record }">
              <div class="flex al-center ju-center">
                <div class="isExpatriate" v-if="record.orderSource?.value === 9">
                  华月
                </div>
                <a @click="lookSmall(record)"> {{ record.vinNo }}</a>
              </div>
            </template>"
            <template #brand="{ record }">
              {{ record.brand }} {{ record.model }}
            </template>
            <template #startAddress="{ record }">
              <div>起：{{ record.startAddress }}</div>
              <div>终：{{ record.endAddress }}</div>
            </template>
            <template #transportFee="{ record }">
              <a @click="changeFee(record)">{{ record.transportFee }}</a>
            </template>
            <template #add="{ record }">
              <div v-if="record.isInTruck?.value === 0">已完成</div>
              <div v-else>
                <a-popconfirm title="确定退板吗？" ok-text="确定" cancel-text="取消" @confirm="retreatCar(record)">
                  <a class="m-r1">退板</a>
                </a-popconfirm>
                <a-popconfirm title="确定到达吗？" ok-text="确定" cancel-text="取消" @confirm="arriveAdd(record)">
                  <a v-if="msgInfo.truckStatus?.value === 3">到达</a>
                </a-popconfirm>
              </div>
            </template>
          </ly-table>
        </div>
      </div>
      <div v-if="msgInfo.truckStatus?.value === 3">
        <div class="titel m-t1 m-b1">在途位置</div>
        <div class="gpsBox">
          <a-button @click="newGpsAdd" type="primary">+新增位置</a-button>
          <div>
            <div class="content-data m-t1">
              <div v-show="transPosition.length <= 0" style="text-align: center;color:#6B778C" class="m-b1 m-t1">暂无在途信息</div>
              <a-steps v-show="transPosition.length > 0" progress-dot :current="1" direction="vertical">
                <a-step v-for="(item, index) in transPosition" :key="index">
                  <template #title >
                    <div class="flex ju-between">
                      <div style="display:flex">
                        {{ item.location }}
                        <a-typography-paragraph :copyable="{ text: item.location }">
                        </a-typography-paragraph>
                      </div>
                      <div>
                        <a v-if="index == 0" @click="editBtn(item)">编辑</a>
                      </div>
                    </div>
                  </template>
                  <template #description>
                    <div style="width:600px">
                      <!-- <div>备注：{{ item.remark }}</div> -->
                      <div class="flex">{{ item.operator }}<div style="margin-left:10px">{{ item.recordTime }}</div>
                      </div>
                    </div>
                  </template>
                </a-step>
              </a-steps>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal v-model:visible="newAddVisible" footer="" :title="title" width="20%" destroyOnClose>
      <ly-formList ref="formListRef" :fromList="fromList" @getVal="getVal" @cancel="newAddVisible = false"></ly-formList>
    </a-modal>
    <a-modal v-model:visible="arriveVisible" title="选择卸车地" footer="" width="20%" destroyOnClose>
      <ly-formList ref="formListRef" :fromList="arriveFromList" @getVal="submit" @cancel="arriveVisible = false"></ly-formList>
    </a-modal>
    <a-modal v-model:visible="changeFeeVisible" title="修改内容" footer="" width="30%" destroyOnClose>
      <ly-formList ref="formListRef" :fromList="changeFeeFromList" @getVal="changeFeeOk" @cancel="changeFeeVisible = false"></ly-formList>
    </a-modal>
     <!-- 小车信息 -->
     <SmallVehicle v-model:smallVisible="smallVisible" @success="completeNew" v-if="smallVisible" :orderVehicleId="orderVehicleId"></SmallVehicle>
      <!-- 更换司机 -->
     <ChangeDriver v-if="driverVisible" :carrierId="msgInfo.customerId" :transportId="msgInfo.transportId" @success="reqTransportInfo()"   v-model:visible="driverVisible"></ChangeDriver>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { transportInfo, transportGpsPage, transportGpsAdd, transportArrival, vehicleBackPlate, vehicleChangeFee } from '@/api/transport/vehicle'
import { message } from 'ant-design-vue';
import SmallVehicle from './smallVehicle.vue';
import   ChangeDriver from '@/components/changeDriver'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  CarID: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:visible', 'complete'])
const smallVisible = ref(false)
const data = ref([])
const msgInfo = ref({})
const transPosition = ref([])
const newAddVisible = ref(false)
const arriveVisible = ref(false)
const orderVehicleIds = ref([])
const changeFeeVisible = ref(false)
const changeFeeItem = ref({})
const editID = ref('')
const title = ref('')
const orderVehicleId = ref('')
const formListRef = ref(null)
const driverVisible = ref(false)


const changeDriver = () => {
  driverVisible.value = true
}
// 查看小车
const lookSmall = (record) => {
  orderVehicleId.value = record.orderVehicleId
  smallVisible.value = true
}
// 修改费用
const changeFee = (record) => {
  changeFeeVisible.value = true
  changeFeeItem.value = record
}
const changeFeeOk = (e) => {
  let msg = {
    totvId: changeFeeItem.value.transOrderTruckVehicleId,
    transportId: msgInfo.value.transportId
  }
  Object.assign(msg, e)
  formListRef.value.fileLoading = true
  vehicleChangeFee(msg).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    changeFeeVisible.value = false
    reqTransportInfo()
  }).finally(() => {
    if(formListRef.value){
      formListRef.value.fileLoading = false
    }
  })
}
//批量到达
const allArrive = () => {
  let list = data.value.filter(item => item.isInTruck?.value !== 0)
  orderVehicleIds.value = list.map(item => item.orderVehicleId)
  arriveVisible.value = true
}
// 车辆到达
const arriveAdd = (record) => {
  orderVehicleIds.value = [record.orderVehicleId]
  arriveVisible.value = true
}
// 退板
const retreatCar = (record) => {
  vehicleBackPlate({
    transportId: msgInfo.value.transportId,
    totvIds: [record.transOrderTruckVehicleId]
  }).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    emit('complete')
    reqTransportInfo()
  })
}
const submit = (e) => {
  if (e.address.length === 1) {
    message.error('卸车地必须选择市')
    return
  }
  let stopAddress = {
    province: e.address[0],
    city: e.address[1],
    area: e.address.lenght === 3 ? e.address[2] : '',
    details: ''
  }
  reqTransportArrival(stopAddress)
}
const reqTransportArrival = (stopAddress) => {
  formListRef.value.fileLoading = true
  transportArrival({
    transportId: props.CarID,
    orderVehicleIds: orderVehicleIds.value,
    stopAddress: stopAddress
  }).then(res => {
    if (res.code !== 10000) return
    arriveVisible.value = false
    reqTransportInfo()
    emit('complete')
    message.success(res.msg)

  }).finally(() => {
    if(formListRef.value){
      formListRef.value.fileLoading = false
    }
  })
}

//修改位置
const editBtn = (item) => {
   title.value = '编辑位置信息'
  editID.value = item.gpsId
  newAddVisible.value = true
}
//新增位置
const newGpsAdd = () => {
  editID.value = '',
  title.value = '新增位置信息'
  newAddVisible.value = true
}
const getVal = (e) => {
  if (e.address.length === 1) {
    message.error('位置必须选择城市')
    return
  }
  let addressDTO = {
    province: e.address[0],
    city: e.address[1],
    area: e.address.length === 3 ? e.address[2] : '',
    details: e.details
  }
  let msg = {
    transportId: props.CarID,
    addressDTO: addressDTO,
    gpsId:editID.value
  }
  formListRef.value.fileLoading = true
  transportGpsAdd(msg).then(res => {
    if (res.code !== 10000) return
    newAddVisible.value = false
    message.success(res.msg)
    reqTransportGpsPage()
    emit('complete')
  }).finally(() => {
    if(formListRef.value){
      formListRef.value.fileLoading = false
    }
  })
}
const cancel = () => {
  emit('update:visible', false)
}
const completeNew = () => {
  emit('complete')
}
const reqTransportInfo = () => {
  transportInfo(props.CarID).then(res => {
    if (res.code !== 10000) return
    console.log(res);
    data.value = res.data.vehicleList
    msgInfo.value = res.data
    info.value.forEach(item => {
      if (item.obj) {
        item.value = res.data[item.key]?.label
      } else {
        if (item.key == 'startAddress') {
          item.value = res.data.startAddress + '-' + res.data.endAddress
        } else {
          item.value = res.data[item.key]
        }
      }
    })
  })
}
const reqTransportGpsPage = () => {
  transportGpsPage(props.CarID).then(res => {
    if (res.code !== 10000) return
    transPosition.value = res.data.records
    console.log(res);
  })
}
onMounted(() => {
  reqTransportInfo()
  reqTransportGpsPage()
})
const changeFeeFromList = ref([
  {
    label: '金额',
    name: 'changeFee',
    type: 'inputNumber',
    placeholder: '请输入',
    value: null,
    prop: 'changeFee',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入',
        trigger: 'blur',
        type: 'number'
      },
    ]
  },
  {
    label: '备注',
    name: 'reason',
    type: 'textarea',
    placeholder: '请输入',
    value: null,
    prop: 'reason',
    width: '100%',
    rules: [
      {
        required: false,
        message: '请输入',
        trigger: 'blur'
      },
    ]
  },
])
const arriveFromList = ref([
  {
    label: '卸车地',
    name: 'address',
    type: 'address',
    placeholder: '请选择',
    value: null,
    prop: 'address',
    width: '100%',
    changeOn: true,
    rules: [
      {
        required: true,
        message: '请选择',
        trigger: 'change',
        type: 'array'
      },
    ]
  },
])
const fromList = ref([
  {
    label: '所在地区',
    name: 'address',
    type: 'address',
    placeholder: '请选择',
    value: null,
    prop: 'address',
    changeOn: true,
    width: '100%',
    rules: [
      {
        required: true,
        message: '请选择',
        trigger: 'change',
        type: 'array'
      },
    ]
  },
  {
    label: '详细地址',
    name: 'details',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'details',
    width: '100%',
    rules: [
      {
        required: false,
        message: '请输入',
        trigger: 'blur'
      },
    ]
  },
])
const columns = ref([
  {
    title: '车牌号',
    dataIndex: 'vinNo',
    align: 'center',
    slots: { customRender: 'vinNo' }
  },
  {
    title: '车型',
    dataIndex: 'brand',
    align: 'center',
    slots: {
      customRender: 'brand'
    }
  },
  {
    title: '单台价格',
    dataIndex: 'transportFee',
    slots: { customRender: 'transportFee' },
    align: 'center',
  },
  {
    title: '装车人',
    dataIndex: 'loadingOperator',
    align: 'center',
  },
  {
    title: '起始地',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: {
      customRender: 'add'
    },
    align: 'center',
  },
])
const info = ref([
  {
    label: '线路名称',
    value: '',
    key: 'startAddress'
  },
  {
    label: '状态',
    value: '',
    key: 'truckStatus',
    obj: 'label'
  },
  {
    label: '已占',
    value: '',
    key: 'usedParkPlace'
  },
  {
    label: '安排时间',
    value: '',
    key: 'loadingTime'
  },
  {
    label: '发车时间',
    value: '',
    key: 'departureTime'
  },
  {
    label: '发车人',
    value: '',
    key: 'dispatcher'
  },
  {
    label: '大车总运费',
    value: '',
    key: 'freightCost'
  },
  {
    label: '司机姓名',
    value: '',
    key: 'driverName',
    add:'add'
  },
  {
    label: '司机电话',
    value: '',
    key: 'driverTel'
  },
  {
    label: 'GPS类型',
    value: '',
    key: 'gpsType',
    obj: 'label'
  },
])
</script>

<style lang="less" scoped>
:deep(.ant-steps-item-content) {
  width: auto;
}
.titel {
  font-weight: 700;
  color: #191919;
  font-size: 15px;
}

.infoBox {
  margin-top: 5px;
  width: 100%;
  border: 1px solid rgba(238, 238, 238, 1);
  font-size: 12px;
  padding: 10px;
  background: rgba(242, 242, 242, 0.35);
}

.gpsBox {
  width: 100%;
  border: 1px solid rgba(238, 238, 238, 1);
  font-size: 12px;
  padding: 10px;
  background: rgba(242, 242, 242, 0.35);
}
.content-data {
  max-height: 300px;
  overflow: auto;
  padding: 5px;
  padding-bottom: 0;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  flex-wrap: nowrap;
  background: rgba(242, 242, 242, 0.3);

  .plant-data {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .city-data {
      flex-wrap: nowrap;
      white-space: nowrap;
      margin: 0 5px;
    }

    .detail-data-plant {
      min-width: 200px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }

  // .plant-data:last-child {
  //   flex: 0;
  // }
}

.orderVehicle {
  height: 250px;
  overflow: auto;
}

.isExpatriate {
  width: 24px;
  height: 16px;
  background-color: #001529;
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
:deep(.ant-steps-item-title){
  width: 100%;
}
</style>