<template>
  <div>
    <a-modal :visible="smallVisible" footer="" width="50%" @cancel="cancel">
      <template #title>
        <div class="flex ju-between fz-14 al-center" style="padding-right: 25px;font-weight: 400;">
          <div>
            <span style="font-size:15px;">{{ detailData.vinNo }}{{ detailData.brand }}{{ detailData.model }}</span>
            <a class="m-l2" @click="vehImgOpen">车辆照片({{ detailData.picNum }})</a>
            <a class="m-l2" @click="addItionalFeeVisible = true">附加费</a>
            <a class="m-l2" v-if="detailData.isPlaceIn?.value === 1" @click="openEntrust(1)">取车委托</a>
            <a class="m-l2" @click="openEntrust(2)">运车委托</a>
            <a class="m-l2" v-if="detailData.isTakeOut?.value === 1" @click="openEntrust(3)">送车委托</a>
          </div>
          <div style="color:#555">
            {{ detailData.sourceMan }} {{ detailData.orderTime }}
          </div>
        </div>
      </template>
      <div>
        <div class="content-title">
          <div style="width:100%;display: flex;justify-content: space-between">
            <div>基本信息</div>
          </div>
        </div>
        <div>
          <div class="detail-data">
            <div class="data-title">订单信息</div>
            <div class="data-content">{{ detailData.orderId || '' }}<span class="m-l1">{{ detailData.orderSource?.label }}</span> <span class="m-l1">{{ detailData.officeOrgName }}</span>
            </div>
            <div class="data-content">
              <a-tag color="orange" v-show="detailData.orderStatus?.value === 1 || detailData.orderStatus?.value === 2">
                {{ detailData.orderStatus?.label }}</a-tag>
              <a-tag color="red" v-show="detailData.orderStatus?.value === 7"> {{ detailData.orderStatus?.label }}</a-tag>
              <a-tag color="blue" v-show="detailData.orderStatus?.value === 9"> {{ detailData.orderStatus?.label
              }}</a-tag>
              <span v-show="detailData.orderStatus?.value !== 1 && detailData.orderStatus?.value !== 2 && detailData.orderStatus?.value !== 7 && detailData.orderStatus?.value !== 9">{{
                  detailData.orderStatus?.label }}</span>
            </div>
            <div class="data-content">共<span style="margin: 0 5px;font-weight:bold">{{  detailData.vehicleNum
            }}</span>辆</div>
            <span class="m-l3" @click="CollectionAdd(detailData)" v-if="detailData.isPeerCollection?.value === 1"><a >代收码</a></span>
          </div>
          <div class="detail-data">
            <div class="data-title">客户信息</div>
            <div class="data-content">{{ detailData.customerName || '' }}</div>
            <div class="data-content">{{ detailData.isInside?.label || '' }}</div>
          </div>
          <div class="detail-data">
            <div class="data-title">起点-终点</div>
            <div class="data-content" style="font-weight:bold">{{ detailData.startAddress || '' }}-{{
              detailData.endAddress || '' }}</div>
          </div>
          <div class="detail-data">
            <div class="data-title">取车信息</div>
            <div class="data-content" v-show="detailData.isPlaceIn?.value === 0">否</div>
            <div class="data-content" v-show="detailData.pickType && detailData.isPlaceIn?.value === 1">{{
              detailData.pickType?.label || '' }}</div>
            <div class="data-content" style="font-weight:bold" v-show="detailData.isPlaceIn?.value === 1">{{
              detailData.placeInAddress }}</div>
          </div>
          <div class="detail-data">
            <div class="data-title">送车信息</div>
            <div class="data-content" v-show="detailData.isTakeOut?.value === 0">否</div>
            <div class="data-content" v-show="detailData.takeType && detailData.isTakeOut?.value === 1">{{
              detailData.takeType?.label || '' }}</div>
            <div class="data-content" style="font-weight:bold" v-show="detailData.isTakeOut?.value === 1">{{
              detailData.takeOutAddress }}</div>
          </div>
          <div class="detail-data">
            <div class="data-title">特殊需求</div>
            <div class="data-content" v-show="detailData.isInvoice?.value === 1"><a-tag color="red">发票</a-tag>
            </div>
            <div class="data-content" v-show="detailData.isNoticeReleaseCar?.value === 1"><a-tag color="red">等通知放车</a-tag>
            </div>
            <div class="data-content" v-show="detailData.isRefund?.value === 1"><a-tag color="red">返款</a-tag></div>
          </div>
          <div class="detail-data">
            <div class="data-title">财务信息</div>
            <!-- <div class="data-content">成本：{{ detailData.amt || '' }}</div>
            <div class="data-content">优惠券：{{ detailData.coupon || '' }}</div>
            <div class="data-content">产品：{{ detailData.productName || '' }}</div> -->
            <div class="data-content">
              合同总金额：<a-tag v-show="detailData.contractAmt" color="red">￥{{ detailData.contractAmt || '' }}</a-tag>
              <span v-show="detailData.paymentArrival > 0"> 订单到付：<a-tag color="red">{{ detailData.paymentArrival
              }}</a-tag></span>
              <span> 返款：<a-tag color="red">{{ detailData.refundFee }}</a-tag></span>
              <a @click="openPayQRC" v-show="detailData.orderStatus?.value === 1 || detailData.orderStatus?.value === 2 || detailData.orderStatus?.value === 9">
                <span v-if="!detailData.isPeerCollection || detailData.isPeerCollection?.value === 0">生成支付码</span>
              </a>
            </div>
          </div>
          <div class="detail-data">
            <div class="data-title">结算信息</div>
            <div class="data-content"><a-tag color="blue">{{ detailData.orderSettlement?.label || '' }}</a-tag></div>
            <div class="data-content"><a-tag v-show="detailData.settlementStatus !== 0" color="green">{{
              detailData.settlementStatus?.label || '' }}</a-tag></div>
            <div class="data-content"><a-tag v-show="detailData.settlementStatus === 0" color="red">{{
              detailData.settlementStatus?.label || '' }}</a-tag></div>
          </div>
          <div class="detail-data" v-show="detailData.orderRemark && detailData.orderRemark !== ''">
            <div class="data-title">订单备注</div>
            <div class="data-content" style="color:#f00">{{ detailData.orderRemark }}</div>
          </div>
          <div class="detail-data" v-show="detailData.remark && detailData.remark !== ''">
            <div class="data-title">小车备注</div>
            <div class="data-content" style="color:#f00">{{ detailData.remark }}</div>
          </div>
        </div>
      </div>

      <!-- 装车信息 -->
      <div>
        <ly-table :scroll="{x:1300,y:300}" :columns="waitColumns" rowKey="vehicleId" ref="tableRef" size="small" :data="waitData">
          <template #driverName="{ record }">
            <div>{{ record.driverName }} <a @click="changeDriver(record)">更改</a>  </div>
            <div>{{ record.driverMobile }}</div>
            
          </template>
          <template #transportFee="{ record }">
            <a @click="changeFee(record)">{{ record.transportFee }}</a>
          </template>
          <template #startAddress="{ record }">
            {{ record.startAddress }}
            <div>{{ record.endAddress }}</div>
          </template>
          <template #add="{ record,index }">
            <a-popconfirm title="确认撤销吗?" ok-text="确认" cancel-text="取消" @confirm="revoke(record)" >
              <a v-if="index+1 === waitData.length&&record.complete?.value == 1" >撤销</a>
            </a-popconfirm>
          </template>
        </ly-table>
      </div>
    </a-modal>
    <!-- 修改价格 -->
    <a-modal v-model:visible="changeFeeVisible" title="修改内容" footer="" width="30%" destroyOnClose>
      <ly-formList ref="formListRef" :fromList="changeFeeFromList" @getVal="changeFeeOk" @cancel="changeFeeVisible = false"></ly-formList>
    </a-modal>
    <!-- 车辆照片 -->
    <div>
      <ly-imageModal v-model:showModal="showModal" @success="success" v-if="showModal" :orderVehicleId="orderVehicleId"></ly-imageModal>
    </div>
    <a-modal v-model:visible="addItionalFeeVisible" footer="" width="70%" destroyOnClose>
      <template #title>
        <div class="flex ju-between al-center  fz-14" style="padding-right: 25px;font-weight: 400;">
          <div style="font-size:15px;"> <span>{{ detailData.vinNo }}{{ detailData.brand }}{{ detailData.model }}</span>-附加费 <a class="m-l2" @click="addItionalFee">附加费上报</a></div>
          <div style="color:#555 m-r2">
            {{ detailData.salesman }} {{ detailData.orderTime }}
          </div>
        </div>
      </template>
      <AdditionalFeeList ref="AdditionalFeeListRef" :orderVehicleId="detailData.orderVehicleId"></AdditionalFeeList>
    </a-modal>
    <!-- 附加费 -->
    <div>
      <ly-modal v-model:visible="surchargeVisible" title="附加费" :footer="false" width="30%">
        <div>
          <div>已选车辆</div>
          <div class="itemBox flex al-center ju-between">
            <div class="flex">
              <div style="min-width:100px">{{ detailData.vinNo }}</div>
            </div>
          </div>
        </div>
        <ly-formList ref="formListRef" @select="select" v-if="surchargeVisible" @cancel="surchargeVisible = false" :maxMun="1" :fromList="surFormList" @getVal="getValSur" @search="search">
        </ly-formList>
      </ly-modal>
    </div>
    <!-- 支付码 -->
    <PayQRC ref="payQRCref" />
    <!-- 委托 -->
    <EntrustModal v-model:visible="entrustVisible" :type="entrustType" :dispatchDetail="detailData" />
      <!-- 付款码 -->
      <PayModal ref="payQRCrefModal" />
          <!-- 更换司机 -->
     <ChangeDriver v-if="driverVisible" :carrierId="msgInfo.customerId" :transportId="msgInfo.transTruckId" @success="reqTransportVehicle()"   v-model:visible="driverVisible"></ChangeDriver>
  </div>
</template>

<script setup>    
import { onMounted, ref } from 'vue'
import { waitIptData, alreadyIptData, waitColumns, alreayColumns, surFormList } from './smallVehicle'
import { transportVehicle, vehicleChangeFee, transportOpsEdit } from '@/api/transport/vehicle'
import { debounce } from '@/utils/util.js';
import { batchvehicleFee, driverList } from '@/api/order/order'
import { pageList } from '@/api/crmManagement/crm'
import { cloneDeep } from 'lodash-es';
import { message } from 'ant-design-vue';
import PayQRC from '@/components/payQRC'
import AdditionalFeeList from './additionalFeeList.vue';
import EntrustModal from '../components/entrustModal'
import PayModal from '@/views/transport/components/payModal'
import   ChangeDriver from '@/components/changeDriver'

const props = defineProps({
  smallVisible: {
    type: Boolean,
    default: false
  },
  orderVehicleId: {
    type: String,
    default: ''
  }
})
const driverVisible = ref(false)
const msgInfo = ref({})
const formListRef = ref(null)
const payQRCrefModal= ref(null)
const changeFeeItem = ref({})
const changeFeeVisible = ref(false)
const entrustVisible = ref(false)
const entrustType = ref(0)
const AdditionalFeeListRef = ref(null)
const showModal = ref(false)
const surchargeVisible = ref(false)
const detailData = ref({})
const addItionalFeeVisible = ref(false)
const waitData = ref([])
const state = ref({
  timer: null,
})
const payQRCref = ref(null)

const changeDriver = (record) => {
  msgInfo.value = record
  driverVisible.value = true
}
const openPayQRC = () => {
  let record = detailData.value
  let obj = {
    startAddress: record.startAddress,
    endAddress: record.endAddress,
    frId: '1',
    orderId: record.orderId || record.id,
    payType: '1',
    ovId: record.orderVehicleId,
    vehicleList: record.vinNo
  }
  setTimeout(() => {
    payQRCref.value.openWindow(obj)
  }, 10)
}
const vehImgOpen = () => {
  showModal.value = true
}
const emit = defineEmits(['update:smallVisible','success'])
const cancel = () => {
  emit('update:smallVisible', false)
}
const openEntrust = (type) => {
  entrustType.value = type
  entrustVisible.value = true
}
// 代收码
const CollectionAdd = (item) => {
  let obj = {
    startAddress: item.startAddress,
    endAddress: item.endAddress,
    taskId: item.taskId,
    orderId: item.orderId,
    payType: 1,
    vinNo:item.vinNo
  } 
  setTimeout(() => {
    payQRCrefModal.value.openWindow(obj)
  }, 10)
}
// 撤销
const revoke = (e) => {
  transportOpsEdit({
    params: {
      totvId: e.totvId,
      transportTruckId: e.transTruckId
    },
    subType: 104,
    type: 1
  }).then(res => {
    if (res.code !== 10000) return
    message.success('撤销成功')
    reqTransportVehicle()
    emit('success')
  })
}
// 修改费用
const changeFee = (record) => {
  changeFeeVisible.value = true
  changeFeeItem.value = record

}
const changeFeeOk = (e) => {
  let msg = {
    totvId: changeFeeItem.value.totvId,
    transportId: changeFeeItem.value.transTruckId
  }
  Object.assign(msg, e)
  formListRef.value.fileLoading = true
  vehicleChangeFee(msg).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    changeFeeVisible.value = false
    reqTransportVehicle()
  }).finally(() => {
    if( formListRef.value){
      formListRef.value.fileLoading = false
    }
  })
}

const select = (e) => {
  driverList({
    customerId: e.value
  }).then(res => {
    if (res.code !== 10000) return
    surFormList.value[2].opt = res.data.map(item => {
      return {
        label: item.name,
        value: item.id
      }
    })
  })
}

// 增加附加费
const addItionalFee = () => {
  surFormList.value[1].opt = []
  surFormList.value[2].opt = []
  surchargeVisible.value = true
}

//搜索框
const searchVal = ref('')
const search = (e) => {
  searchVal.value = e
  debounce(state, reqPage, 1000)
}
const reqPage = () => {
  pageList({ name: searchVal.value }).then(res => {
    if (res.code !== 10000) return
    let list = []
    res.data.forEach(item => {
      list.push(
        {
          label: item.name,
          value: item.id
        }
      )
    })
    surFormList.value[1].opt = list
  })
}
const getValSur = (e, ids) => {
  formListRef.value.fileLoading = true
  let val = cloneDeep(e)
  val.fileId = ids.map(item => item.id)[0]
  val.orderVehicleIds = [detailData.value.orderVehicleId]
  batchvehicleFee(val).then(res => {
    if (res.code !== 10000) return
    message.success('操作成功')
    surchargeVisible.value = false
    AdditionalFeeListRef.value.reqOrderVehicleFeePage()
  }).finally(() => {
    if( formListRef.value){
      formListRef.value.fileLoading = false
    }
  })
}
const reqTransportVehicle = () => {
  transportVehicle(props.orderVehicleId).then(res => {
    if (res.code !== 10000) return
    detailData.value = res.data
    waitData.value = res.data.details
    console.log(res);
  })
}
const success = () => {
  reqTransportVehicle()
}
onMounted(() => {
  reqTransportVehicle()
})
const changeFeeFromList = ref([
  {
    label: '金额',
    name: 'changeFee',
    type: 'inputNumber',
    placeholder: '请输入',
    value: null,
    prop: 'changeFee',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入',
        trigger: 'blur',
        type: 'number'
      },
    ]
  },
  {
    label: '备注',
    name: 'reason',
    type: 'textarea',
    placeholder: '请输入',
    value: null,
    prop: 'reason',
    width: '100%',
    rules: [
      {
        required: false,
        message: '请输入',
        trigger: 'blur'
      },
    ]
  },
])
</script>

<style lang="less" scoped>
.detail-data {
  display: flex;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-size: 12px;
  flex-wrap: nowrap;
  border: 1px solid #eee;
  background: rgba(242, 242, 242, 0.3);

  .data-title {
    width: 100px;
    color: #6b778c;
  }

  .data-content {
    margin-left: 30px;
    color: #344563;
  }

  .special-div {
    color: #fff;
    padding: 1px 2px;
    background: #0066cc;
  }
}

.loading-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.content-data {
  width: 100%;
  .content-div {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
  }
}
.itemBox {
  margin-top: 4px;
  padding: 4px 5px;
  border: 1px solid rgb(217, 217, 217);
  background: rgb(247, 247, 247);
  color: #6b778c;
  font-size: 12px;
}
</style>